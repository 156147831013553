/*
 * Common for both mobile and desktop
 */

html, body, div, form, label, input, select, textarea, button, h1, h2, h3, h4, h5, h6, img, p, ul, li, dl, dt, dd {
    margin:0;
    padding:0;
    outline:none;
}

html {
    height:100%;
}

a {
    text-decoration:none;
}

a img {
    border:0;
}

.float-left {
    float:left;
}

.float-right {
    float:right;
}

.clearfix {
    clear:both;
    display:block;
}

.hidden {
    display:none !important;
}

.fauxhide {
    height:0 !important;
    min-height:0 !important;
    width:0 !important;
    visibility:hidden !important;
    overflow:hidden !important;
    opacity:0 !important;
}

.only-desktop {
    display:block;
}

table.only-desktop {
    display:table
}

.only-mobile {
    display:none;
}

body.activity, body.activity a {
    cursor:progress;
}

#main-content.downstream {
    .article {
        p {
            margin-bottom:12pt;
        }
        a {
            text-decoration:underline;
        }
        ul {
            list-style-position:inside;
            list-style-type:square;
            margin-bottom:12pt;
        }
        li {
            margin-left:25px;
        }
        .lighter {
            color:gray;
            font-style:italic;
            a {
                color:gray;
            }
        }
    }
}

#trusteID {
    padding:15px 15px 0 15px;
    margin-bottom:10px;
    a img {
        float:left;
        margin-right:20px;
    }
}

#main-content.sitemap {
    .article {
        li {
            margin-left:3px;
            margin-bottom:2px;
            a {
                text-decoration:none;
                &:hover {
                    text-decoration:underline;
                }
            }
        }
    }
}

.article-link-display {
    li.directory {
        list-style-type:none;
    }
}

// region Start common inner stuff
// the default will have the CTA image to the right
html.lp-defaults-yes {
//    body {
//        color:#000;
//        font-size:10pt;
//        background:#FFF;
//        font-family:Arial;
//    }

//    .content-inner-container {
//        max-width:960px;
//        margin:0 auto;
//    }

//    #lp-cta-main {
//        margin-bottom:15px;
//        .content-inner-container {
//            min-height:380px;
//            background:transparent url('/Assets/Images/LandingPages/placeholder.gif?awlav=AWL_CACHE_BUSTING_LM_BUILD_VERSION') right 0 no-repeat;
//        }
//        .cta-content-wrapper {
//            width:400px;
//        }
//    }
    
    .content-container {
        ul {
            margin-left:20px;
            margin-bottom:15px;
        }
    }
    
    h2 {
        color:#333;
        font-size:14pt;
        font-weight:bold;
    }
    p {
        margin-bottom:15px;
        color:#111;
        line-height:1.4;
        a {
            text-decoration:underline;
        }
    }

    .cta-content-wrapper {
//        padding-top:30px;
    }

//    .cta2-get-quote {
//        margin-top:15px;
//        label {
//            float:left;
//            font-size:15px;
//            font-weight:bold;
//            margin-right:10px;
//            padding-top:5px;
//            text-align:right;
//            width:90px;
//        }
//    }

    .short-form {
        margin-top:18px;
        margin-bottom:18px;
        overflow:hidden;
        clear:both;
        padding:10px 0;
        .content-inner-container {
            width:455px;
        }
        h3 {
            float:left;
            margin-top:4px;
            margin-right:8px;
            font-weight:normal;
            font-size:14pt;
            color:#333;
        }
        label {
            display:none;
        }
        input.zip-code {
            float:left;
            margin:0 8px 0 0;
            width:75px;
            text-align:center;
        }
        #cta2-button {
            float:left;
            margin:0;
        }
    }

    .column {
        float:left;
        width:49%;
    }

    #footer-container {
        position:relative;
        bottom:auto;
        height:auto;
    }

    .trusteClass {
        border:1px dotted #BBB;
    }
    
}   // html.lp-defaults-yes

#reCaptcha-footer {
    margin: 3px 15px;
    color: #414141;
    text-align: center;

    a {
        color: #414141;
    }

    a:hover {
        text-decoration: underline;
    }
}
// endregion

