@import "/wwwroot/Assets/Libs/bootstrap-5/css/bootstrap.css";
@import "/wwwroot/Assets/Styles/LandingPages/Common.queries";

:root {
    --iq-primary-900: #0C5479;
    --iq-primary-900-opposite: #FFFFFF;
    --iq-secondary-900: #2AACD6;
    --iq-secondary-900-opposite: #FFFFFF;
    --iq-tertiary-900: #F47020;
    --iq-tertiary-900-opposite: #FFFFFF;
    --gray-gray-900: #333333;
    --gray-gray-900-opposite: #FFF;
    --gray-gray-800: #3D3D3D;
    --gray-gray-800-opposite: #FFF;
    --gray-gray-700: #474747;
    --gray-gray-700-opposite: #FFF;
    --gray-gray-600: #606060;
    --gray-gray-600-opposite: #FFF;
    --gray-gray-500: #707070;
    --gray-gray-500-opposite: #FFF;
    --gray-gray-400: #7E7E7E;
    --gray-gray-400-opposite: #FFF;
    --gray-gray-300: #CFCFCF;
    --gray-gray-300-opposite: #333;
    --gray-gray-200: #DBDBDB;
    --gray-gray-200-opposite: #333;
    --gray-gray-100: #F3F3F3;
    --gray-gray-100-opposite: #333;
    --gray-gray-1: #FFFFFF;
    --gray-gray-1-opposite: #333;
}

body {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

#lp-section-1 {
    .content-inner-container {
        margin-top: -37px;
    }
}

.intro-lp {
    background-color: #fff;
    background-image: url(/Assets/Sites/insurancequotes_com/images/auto-iq-bg-final-2.png);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: #6c757d;
    font-weight: 100;
    margin-bottom: 1.3rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

#header-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background-color: rgba(255,255,255,.95);
    height: 70px;
}

#header-inner-container {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
}

#logo {
    text-align: left;
    margin-left: 3%;
    margin-top: 1%;

    img {
        width: auto;
        max-height: fit-content;
    }
}

#call-us .number span,
#call-us .number > a {
    display: none;
}

#talk-to-an-agent {
    width: calc(70px + 8px);
    background: #2aacd6;
    position: absolute;
    right: 0;
    top: 0;
    display: flex !important;
    align-items: center;
    border-color: transparent;
    top: 0;
    height: 100%;
    max-height: 72.5px;
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    padding: .25rem 1.5rem;
    color: #fff;
    font-size: 1.25rem;

    a {
        color: transparent;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNi4wMSAyNiI+PHBhdGggZD0iTTE3LjM0IDE4LjQ4bDIuMjQtMi4yNGEyLjIyIDIuMjIgMCAwMTEuMS0uNTggMi4xOCAyLjE4IDAgMDExLjI0LjFsMi43MyAxLjA5YTIuMjggMi4yOCAwIDAxMSAuOCAyLjEzIDIuMTMgMCAwMS4zNyAxLjJ2NWEyIDIgMCAwMS0uMTguODUgMi4zOSAyLjM5IDAgMDEtLjUuNjggMi4yIDIuMiAwIDAxLTEuNi41OEM0LjYgMjQuODEuNzQgOC42MS4wMSAyLjM4YTIuMjkgMi4yOSAwIDAxLjA5LS45QTIuMjUgMi4yNSAwIDAxLjU2LjY5YTIgMiAwIDAxLjc4LS41QTIuMTMgMi4xMyAwIDAxMi4xOCAwaDQuODNhMi4xOSAyLjE5IDAgMDExLjIxLjM4IDIuMTEgMi4xMSAwIDAxLjc5IDFsMS4wOSAyLjczYTIuMiAyLjIgMCAwMS4xMiAxLjI3IDIuMTIgMi4xMiAwIDAxLS41OSAxLjFMNy4zOSA4LjY3czEuMjkgOC43MSA5Ljk1IDkuODF6IiBmaWxsPSIjZmZmIi8+PC9zdmc+");
    }
}

.content-inner-container {
    width: 100%;
    max-width: 1320px;
    margin: 90px auto 0;
    padding-left: 3%;
    padding-right: 3%;
}


#lp-cta-header {
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;

    .site-lp-dynamic-heading-styler {
        font-size: 2.25rem;
        margin-bottom: 0.5rem;

        strong {
            font-weight: 700;
            color: #219EBC;
        }
    }

    h3 {
        font-size: 2.25rem;
        margin-bottom: 0.5rem;
    }
}

.cta-content-wrapper .form-inner-wrapper {
    background-color: #eaf7fb;
    padding: 3.5rem 1.5rem;
    margin-left: -23px;
    margin-right: -23px;
}

form.cta2-get-quote {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    background-clip: padding-box;
    border: solid 1px transparent;
    font-size: .8em;
}

.zipcode {
    border-radius: .5rem .5rem 0 0;
    background-color: #fff;
    border: solid 1px #2aacd6;
    border-bottom: solid 1px transparent;

    input {
        width: 100%;
        padding: 1.5em 1em 1.5em 4.5em;
        border: none;
        color: #212529;
        height: 100%;
        background-color: transparent;
        background-image: url("/Assets/Sites/insurancequotes_com/images/default3/map-icon.svg");
        background-repeat: no-repeat;
        background-position: 1em center;
        background-size: 1.75em;
    }
}

#cta2-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FD9E02;
    border: none;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: .5rem;
    white-space: nowrap;

    a {
        width: 100%;
        padding: 1.5em;
        color: #fff;
        background-image: url(/Assets/Sites/insurancequotes_com/images/default3/chevron-down-white-icon.svg);
        background-repeat: no-repeat;
        background-position: 94% 52%;
    }
}

.iq-cta__tagline {
    text-transform: uppercase;
    color: #495057;
    padding: 0.5rem 0.5rem;

    span {
        color: #219EBC;
        font-weight: bold;
    }
}

.carriers {
    margin: 3% auto;
}

.has-text-color.has-iq-secondary-900-color, .has-text-color.has-iq-secondary-900-color a {
    color: #2AACD6 !important;
}

h1.text-secondary > strong, .text-secondary.h1 > strong, h2.text-secondary > strong, .text-secondary.h2 > strong,
h3.text-secondary > strong, .text-secondary.h3 > strong, h4.text-secondary > strong, .text-secondary.h4 > strong,
h5.text-secondary > strong, .text-secondary.h5 > strong, h6.text-secondary > strong, .text-secondary.h6 > strong {
    color: #0c5479;
}

.has-text-align-center {
    margin-left: auto;
    margin-right: auto;
}

.has-text-align-center {
    text-align: center;
}

#h-why-you-should-use-insurancequotes {
    font-size: 1.5rem;
}

.iq-stats__stat {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #ced4da;
}

.iq-stats__stat:first-child {
    margin-top: 0;
}

.iq-stats__text, .iq-stats__subtext {
    font-weight: 700;
}

.iq-stats__text {
    color: #2aacd6;
    font-size: 2.5rem;
    line-height: 1.2;
}

.iq-stats__subtext {
    text-transform: uppercase;
    color: #6c757d;
    font-size: 1rem;
}

.iq-testimonial {
    margin-bottom: 4rem;
}

.iq-testimonial__icon {
    margin-bottom: 2rem;
}

.iq-testimonial__title {
    font-size: 1.25rem;
    font-weight: 600;
}

.iq-testimonial.is-dark .iq-testimonial__blurb, .iq-testimonial.is-dark .iq-testimonial__name,
.iq-testimonial.is-dark .iq-testimonial__title, .iq-testimonial.is-dark .iq-testimonial__subtext {
    color: #212529;
}

.iq-testimonial__blurb, .iq-testimonial__subtext {
    font-size: 1em;
    opacity: .6;
}

.iq-testimonial__blurb {
    margin-bottom: 2rem;
}

.iq-testimonial .iq-testimonial__image {
    height: 5rem;
    width: 5rem;
    border-radius: 15px;

    img {
        height: 5rem;
        border-radius: 15px;
        max-width: 100%;
        object-fit: cover;
    }
}

.iq-testimonial__name, .iq-testimonial__subtext {
    font-size: 1rem;
    margin-bottom: 0;
}

.iq-testimonial__name {
    font-weight: 600;
}

#footer-container {
    background-color: #3f4648;
    border-top: .5rem solid #FD9E02;
    color: #fff;
    padding: 3%;
    position: relative;

    .links {
        margin: 0 auto;
        width: 100%;
        text-align: center;

        li {
            display: inline-block;
            color: #bdbdbd;
            margin: 0 0 15px;

            a {
                margin: 0 10px
            }
        }
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    #footer-two p {
        font-size: 12px;
        text-align: center;
        margin-bottom: 5px;
    }
}

html.landingpage.downstream {
    #main-content-container {
        margin-top: 7rem;
    }
}

@media screen and (max-width: 321px) {
    #lp-cta-header .site-lp-dynamic-heading-styler strong {
        font-size: 0.75em;
    }

    .cta-content-wrapper .form-inner-wrapper {
        margin-right: -9.5px;
    }
}

@media screen and (min-width: 321px) and (max-width: 361px) {
    #lp-cta-header .site-lp-dynamic-heading-styler strong {
        font-size: 0.75em;
    }

    .cta-content-wrapper .form-inner-wrapper {
        margin-right: -10.5px;
    }
}

@media screen and (min-width: 361px) and (max-width: 376px) {
    #lp-cta-header .site-lp-dynamic-heading-styler strong {
        font-size: 0.75em;
    }

    .cta-content-wrapper .form-inner-wrapper {
        margin-right: -11.6px;
    }
}

@media screen and (min-width: 376px) and (max-width: 412px) {
    .cta-content-wrapper .form-inner-wrapper {
        margin-right: -12px;
    }
}

@media screen and (min-width: 412px) and (max-width: 416px) {
    .cta-content-wrapper .form-inner-wrapper {
        margin-right: -12.5px;
    }
}

@media screen and (max-width: 767px) {
    .intro-lp {
        background-size: 100% auto !important;
    }
}

@media screen and (min-width: 767px) {

    #logo {
        text-align: left;
        margin-left: 3%;
        display: inline-block;

        img {
            max-height: fit-content;
        }
    }

    #call-us {
        display: inline-block;
        float: right;

        .number {
            margin-top: 10%;

            span {
                display: block;
                width: auto;
                height: auto;
                vertical-align: middle;
                background: none;
                color: #002736;
            }

            a {
                display: block;
                width: auto;
                height: auto;
                vertical-align: middle;
                background: none;
                color: #002736;
            }
        }
    }

    #talk-to-an-agent {
        display: none !important;
    }

    #lp-cta-header {
        .site-lp-dynamic-heading-styler, h3 {
            font-size: 3rem;
        }
    }

    .cta-content-wrapper .form-inner-wrapper {
        background-color: transparent;
        padding: 0;
    }

    form.cta2-get-quote {
        flex-direction: row;
        height: 6rem;
        max-width: 700px;
    }

    .zipcode {
        border-radius: .5rem 0 0 .5rem;
        border: solid 1px #2aacd6;
        border-right: solid 1px transparent;
        flex: 2 2 20em;

        input {
            padding: 1em 1em 1em 3.5em;
            font-size: 1.5em;
        }
    }

    #cta2-button {
        padding: 0;
        flex: 1 1 5em;
        font-size: 1.8em;

        a {
            padding: 1.75em;
            color: #fff;
        }
    }

    .iq-cta__tagline {
        font-size: 1.25rem;
    }

    #lp-section-1 {
        background-color: #0C5479;

        #why-choose {
            padding: 3%;
        }

        .iq-how-works h3 {
            text-align: left;
        }

        .iq-how-works__title {
            color: #2aacd6;
            font-weight: 600;
            font-size: 1.5rem;
            text-align: center;
        }

        .iq-how-works__desc {
            text-align: center;
            color: #fff;
        }
    }

    #h-why-you-should-use-insurancequotes {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .iq-stats__text {
        font-size: 4rem;
    }

    .iq-stats__subtext {
        font-size: 1.25rem;
    }
}
