// Common for mobile only

@media (max-width:767px) {
    .only-desktop {
        display:none !important;
    }

    .only-mobile {
        display:block !important;
    }

    .hidden {
        display:none !important;
    }



    #EnvironmentSection {
        display:none;
    }

    #header-inner-container {
        padding:10px 0;
    }

    // TODO: remove this logo rule and QA each LP
    #logo {
        text-align:center;
        img {
            width:160px;
            max-height:50px;
        }
    }

    html.lp-defaults-yes {
        #lp-cta-main {
            .content-inner-container {
                min-height:0;
            }
            .cta-content-wrapper {
                width:auto;
            }
        }
        .short-form {
            display:none;
        }

        .downstream {
            .short-form {
                display:block;
                padding-left:15px;
                margin-top:0;
                padding-top:0;
                h3 {
                    width:100%;
                    margin-bottom:6px;
                }
            }
        }
    }

}

